@import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Source+Sans+Pro&display=swap');

html {
  font-family: 'Source Sans Pro', sans-serif;
  margin: 10px;
}

h1,h2,h3,h4,h5 {
  font-family: 'Merriweather', serif;
}

.sad-doggo {
  width: 100%;
  height: auto;
}
